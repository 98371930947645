<script setup lang="ts">
import type { MetaSocial } from '~~/../admin/types/components/MetaSocial'
import type { LandingPage } from '~/api/types/generated/LandingPage'
import type { SharedLandingPage } from '~/api/types/generated/SharedLandingPage'
import type { SbContentPageStoryblok, SbLandingPageStoryblok } from '~/types/storyblok'

definePageMeta({ layout: false })

const { $preview } = useNuxtApp()

const { app: { ENVIRONMENT } } = useRuntimeConfig()

const route = useRoute()

const pageSlug = computed(() => {
  const slug = route.params.slug

  if (Array.isArray(slug)) {
    return slug.join('/')
  }

  return slug || 'homepage'
})

const story = useState<{ content: SbLandingPageStoryblok | SbContentPageStoryblok | null, id: number }>(`storyblok-${pageSlug.value}`)

const { find, findOne } = useStrapi()

const storyblokApi = useStoryblokApi()

const { data } = await useAsyncData(
  `landing-pages-${pageSlug.value}`,
  async () => {
    const results = await Promise.allSettled([
      find<LandingPage['attributes'] | undefined>('landing-pages', {
        filters: {
          slug: {
            $eq: pageSlug.value,
          },
        },
        populate: { seo: true },
      }),
      findOne<SharedLandingPage['attributes']>('shared-landing-page'),
      storyblokApi.get(`cdn/stories/${pageSlug.value}`, { version: ENVIRONMENT === 'production' && !$preview ? 'published' : 'draft', resolve_links: 'url' }),
    ])

    const landingPage = results[0].status === 'fulfilled' ? results[0].value : undefined
    const sharedLandingPage = results[1].status === 'fulfilled' ? results[1].value : undefined
    const storyblokStory = results[2].status === 'fulfilled' ? results[2].value : undefined

    story.value = storyblokStory?.data?.story

    return {
      landingPageData: story.value?.content ? undefined : landingPage?.data?.[0]?.attributes ?? undefined,
      sharedLandingPageData: story.value?.content ? undefined : sharedLandingPage?.data?.attributes ?? undefined,
    }
  },
  { watch: [pageSlug] },
)

if (!data.value?.landingPageData && !story.value?.content) {
  showError({ statusCode: 404 })
}

const landingPageData = computed(() => data.value?.landingPageData)
const sharedLandingPageData = ref(data.value?.sharedLandingPageData)

if (landingPageData.value) {
  useSeo({
    metaTitle: landingPageData.value.seo?.metaTitle || landingPageData.value.title,
    metaDescription: landingPageData.value.seo?.metaDescription,
    metaSocial: landingPageData.value.seo?.metaSocial?.map((social): MetaSocial => ({
      socialNetwork: social.socialNetwork,
      title: social.title || landingPageData.value?.title || '',
      description: social.description,
      image: { // todo change to Omit<MetaSocial, 'image'> & { image?: string }
        data: {
          attributes: {
            url: social.image?.data?.attributes.url,
          },
        },
      },
      imageAlternativeText: social.imageAlternativeText,
    } as MetaSocial)) || [],
  })
}

if (sharedLandingPageData.value) {
  useHead({
    script: [
      { key: 'custom-header-javascript', innerHTML: sharedLandingPageData?.value?.headerJavascript },
      { key: 'custom-footer-javascript', innerHTML: sharedLandingPageData?.value?.footerJavascript, tagPosition: 'bodyClose' },
    ],
    style: [
      { key: 'custom-css', innerHTML: sharedLandingPageData?.value?.css },
    ],
  })
}

onBeforeUnmount(() => {
  useHead({
    script: [
      { key: 'custom-header-javascript' },
      { key: 'custom-footer-javascript' },
    ],
    style: [
      { key: 'custom-css' },
    ],
  })
})

watchEffect(() => {
  if (story.value?.content) {
    useHead({
      title: story.value?.content?.pageTitle,
    })
  }
})

onMounted(() => {
  useStoryblokBridge(
    story.value?.id || 0,
    evStory => (story.value = evStory),
  )
})
</script>

<template>
  <div>
    <NuxtLayout
      :is-contact-us-hidden="story?.content?.isContactUsHidden"
      :is-why-us-hidden="story?.content?.isWhyUsHidden"
      :is-wrote-about-us-hidden="story?.content?.isWroteAboutUsHidden"
      name="default"
    >
      <div
        v-if="story?.content"
        :class="{
          'sb-page': story?.content?.component !== 'SBContentPage',
        }"
      >
        <StoryblokComponent :blok="story?.content" />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="landingPageData"
        id="landing-page-content"
        v-html="landingPageData?.content"
      />
      <!-- eslint-enable vue/no-v-html -->
    </NuxtLayout>
  </div>
</template>
